import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import './App.css';
// accounts
const Login = lazy(() => import('./components/accounts/Login'));
const Signup = lazy(() => import('./components/accounts/Signup'));
const SignupSuccess = lazy(() => import('./components/accounts/SignupSuccess'));
const ForgotPassword = lazy(() => import('./components/accounts/ForgotPassword'));
const ForgotPasswordSuccess = lazy(() => import('./components/accounts/ForgotPasswordSuccess'));
const ResetPassword = lazy(() => import('./components/accounts/ResetPassword'));
const ResetPasswordSuccess = lazy(() => import('./components/accounts/ResetPasswordSuccess'));
const Profile = lazy(() => import('./components/accounts/Profile'));
// projects
const ProjectList = lazy(() => import('./components/projects/ProjectList'));
const ProjectDetail = lazy(() => import('./components/projects/ProjectDetail'));
const ProjectForm = lazy(() => import('./components/projects/ProjectForm'));
// experiments
const ExperimentDetail = lazy(() => import('./components/experiments/ExperimentDetail'));
const ExperimentForm = lazy(() => import('./components/experiments/ExperimentForm'));
const ControlStepper = lazy(() => import('./components/experiments/ControlStepper'));
const ControlDetail = lazy(() => import('./components/experiments/ControlDetail'));
// runs
const RunDetail = lazy(() => import('./components/runs/RunDetail'));
const RunStepper = lazy(() => import('./components/runs/RunStepper'));
// Kits
const KitList = lazy(() => import('./components/kits/KitList'));
const KitDetail = lazy(() => import('./components/kits/KitDetail'));
// other
const Error404 = lazy(() => import('./components/utils/Error404'));
const Error503 = lazy(() => import('./components/utils/Error503'));

class App extends React.Component {
	render() {
		return (
			<BrowserRouter>
				<main id="main">
					<Suspense fallback={<p>Loading...</p>}>
						<Switch>
							{/* landing */}
							<Route exact path="/" component={Login} />
							{/* accounts */}
							<Route exact path="/accounts/login/" component={Login} />
							<Route exact path="/accounts/signup/" component={Signup} />
							<Route exact path="/accounts/signup/success/" component={SignupSuccess} />
							<Route exact path="/accounts/forgotpassword/" component={ForgotPassword} />
							<Route exact path="/accounts/forgotpassword/success/" component={ForgotPasswordSuccess} />
							<Route exact path="/accounts/resetpassword/:uidb64/:token/" component={ResetPassword} />
							<Route exact path="/accounts/resetpassword/success/" component={ResetPasswordSuccess} />
							<Route exact path="/accounts/profile/" component={Profile} />
							{/* projects */}
							<Route exact path="/projects/list/" component={ProjectList} />
							<Route exact path="/projects/detail/:id/" component={ProjectDetail} />
							<Route exact path="/projects/form/:id/" component={ProjectForm} />
							{/* experiments */}
							<Route exact path="/experiments/detail/:id/" component={ExperimentDetail} />
							<Route exact path="/experiments/form/:id/" component={ExperimentForm} />
							<Route exact path="/experiments/stepper/:id/" component={ControlStepper} />
							<Route exact path="/experiments/control/:id/" component={ControlDetail} />
							{/* runs */}
							<Route exact path="/runs/detail/:id/" component={RunDetail} />
							<Route exact path="/runs/stepper/:id/" component={RunStepper} />
							{/* kits */}
							<Route exact path="/kits/list/" component={KitList} />
							<Route exact path="/kits/detail/:id/" component={KitDetail} />
							{/* other */}
							<Route exact path="/error/503/" component={Error503} />
							<Route component={Error404} />
						</Switch>
					</Suspense>
				</main>
			</BrowserRouter>
		);
	}
}

export default App;
